<template>
  <div>

    <div v-if="request.status == 'NEW'">
      <div class="section_header mt-3">
        <span>{{ $t('payment.status.enter_data') }}</span>
      </div>

      <div class="alert alert-warning" role="alert" v-html="$t('payment.status.rate_hold')"/>

      <div class="alert alert-warning" role="alert">
        ⚠️ {{ $t('payment.status.rate_source') }} <strong>{{
          ['KZT', 'GEL', 'EUR', 'TRY'].indexOf(request.srcPaySystem.currency.isoCode) == 1
          || ['KZT', 'GEL', 'EUR', 'TRY'].indexOf(request.dstPaySystem.currency.isoCode) == 1 ? 'Whitebit' :
              ['UZS'].indexOf(request.srcPaySystem.currency.isoCode) == 1
              || ['UZS'].indexOf(request.dstPaySystem.currency.isoCode) == 1 ? 'cbu.uz' : 'Binance'
        }}</strong>!
      </div>

      <div v-if="$te('payment.src.' + request.srcPaySystem.alias +  '.danger')" class="alert alert-danger"
           role="alert">
        {{ $t('payment.src.' + request.srcPaySystem.alias + '.danger') }}
      </div>

      <div v-if="$te('payment.' + request.srcPaySystem.alias +  '.warning')" class="alert alert-warning"
           role="alert">
        {{ $t('payment.' + request.srcPaySystem.alias + '.warning') }}
      </div>

      <div v-if="$te('payment.dst.' + request.dstPaySystem.alias +  '.danger')" class="alert alert-danger"
           role="alert">
        {{ $t('payment.dst.' + request.dstPaySystem.alias + '.danger') }}
      </div>

      <div v-if="$te('payment.' + request.srcPaySystem.properties.deposit_provider +  '.warning')"
           class="alert alert-warning" role="alert">
        {{ $t('payment.' + request.srcPaySystem.properties.deposit_provider + '.warning') }}
      </div>

      <div v-if="request.srcPaySystem.currency.crypto" class="alert alert-warning" role="alert">
        {{ $t('payment.aml') }}
      </div>

      <div v-if="request.srcPaySystem.properties.need_confirmation" class="alert alert-info" role="alert">
        {{ $t('payment.confirmation', {n: request.srcPaySystem.properties.need_confirmation}) }}
      </div>

      <div v-if="$te('payment.dst.currency.' + request.dstPaySystem.currency.isoCode +  '.warning')"
           class="alert alert-warning" role="alert">
        {{ $t('payment.dst.currency.' + request.dstPaySystem.currency.isoCode + '.warning') }}
      </div>

      <div v-if="request.srcPaySystem.currency.isoCode==='KZT'" class="alert alert-warning" role="alert">
        ⚠️ {{ $t('payment.status.note_kzt_banks_1') }}
      </div>
      <div v-if="request.srcPaySystem.currency.isoCode==='KZT'" class="alert alert-warning" role="alert">
        ⚠️ {{ $t('payment.status.note_kzt_banks_2') }}
      </div>

      <div v-if="request.srcPaySystem.currency.isoCode==='USD' || request.srcPaySystem.currency.isoCode==='EUR'"
           class="alert alert-warning" role="alert">
        ⚠️ {{ $t('payment.status.note_usd_eur_banks') }}
      </div>

      <div v-if="$te('payment.src.provider.' + request.srcPaySystem.properties.deposit_provider +  '.warning')"
           class="alert alert-warning" role="alert" v-html="$t('payment.src.provider.' + request.srcPaySystem.properties.deposit_provider +  '.warning')"/>

      <div v-if="request.srcPaySystem.srcFields.length > 0 || user === null">
        <div class="subtitle mt-3">{{ $t('payment.status.src_req') }}</div>
        <div class="data_input_wrapper">
          <div v-for="field in request.srcPaySystem.srcFields" :key="field.id">
            <Field :field="field" :validation="$v.srcRequisites" :value="srcRequisites"/>
          </div>
          <div v-if="user === null">
            <input v-model.trim="email" class="mt-3" placeholder="E-mail"/>
            <br/>
            <span>{{ $t('payment.status.email') }}</span>
            <div v-if="$v.email.$dirty && !$v.email.required" style="font-size: 12px; color: #ff1313">
              {{ $t('payment.status.required') }}
            </div>
            <div v-else-if="$v.email.$dirty && !$v.email.regexp" style="font-size: 12px; color: #ff1313">
              {{ $t('payment.status.invalid_format') }}
            </div>
            <div v-else-if="$v.email.$dirty && !$v.email.valid" style="font-size: 12px; color: #ff1313">
              {{ $t('payment.status.email_used') }}
            </div>
          </div>
        </div>
      </div>

      <div class="subtitle mt-4">{{ $t('payment.status.dst_req') }}</div>

      <div class="data_input_wrapper">
        <div v-for="field in request.dstPaySystem.dstFields" :key="field.id">
          <Field :field="field" :validation="$v.dstRequisites" :value="dstRequisites"/>
        </div>
        <div>
          <div>
            <select v-if="request.dstPaySystem.properties.withdrawal_provider === 'payassist-sbp'" name="bank"
                    v-model.trim="dstRequisites.bank_id">
              <option value="100000000111" selected>Сбербанк</option>
              <option value="100000000005">ВТБ</option>
              <option value="100000000008">Альфа-банк</option>
              <option value="100000000004">Т-банк</option>
            </select>
          </div>
          <span v-if="request.dstPaySystem.properties.withdrawal_provider === 'payassist-sbp'">Выберите банк получателя</span>
        </div>
      </div>
      <div class="form-check">
        <input id="flexCheckChecked" v-model="agree" class="form-check-input" type="checkbox">
        <label class="form-check-label" for="flexCheckChecked">{{ $t('payment.status.rules_agree') }}</label>
        <div v-if="$v.agree.$dirty && !$v.agree.checked" style="font-size: 12px; color: #ff1313">
          {{ $t('payment.status.rules_agree_validation') }}
        </div>
      </div>

      <router-link class="btn btn-secondary mt-4" tag="button" to="/">{{
          $t('payment.status.back')
        }}
      </router-link>
      <button :disabled="nextButtonDisabled" class="btn btn-primary mt-4" style="margin-left: 10px" type="button"
              v-on:click="next">{{ $t('payment.status.next') }}
      </button>
    </div>
    <div v-if="request.status == 'FOR_PAYMENT' && !request.verified">
      <Verification :request="request"/>
    </div>
    <div v-if="request.status == 'FOR_PAYMENT' && request.verified">
      <div class="section_header mt-3">
        <span>{{ $t('payment.status.payment') }}</span>

        <vue-countdown-timer
            :day-txt="'days'"
            :end-text="''"
            :end-time="moment(request.modifiedDate).add(20, 'm').unix()"
            :hour-txt="'hours'"
            :interval="1000"
            :minutes-txt="'м.'"
            :seconds-txt="'c.'"
            :start-time="moment(request.modifiedDate).unix()"
            label-position="begin">

          <template slot="start-label"/>


          <template slot="countdown" slot-scope="scope">
            {{ $t('payment.status.rate_refresh') }} {{ scope.props.minutes }}{{ scope.props.minutesTxt }} {{
              scope.props.seconds
            }}{{ scope.props.secondsTxt }} <img src="@/img/time.svg" alt="timer">
          </template>
          <template slot="end-label"/>
          <template slot="end-text"/>

        </vue-countdown-timer>
      </div>

      <div class="alert alert-warning" role="alert">
        {{ $t('payment.status.rate_hold') }}
      </div>

      <div v-if="$te('payment.src.' + request.srcPaySystem.alias +  '.danger')" class="alert alert-danger"
           role="alert">
        {{ $t('payment.src.' + request.srcPaySystem.alias + '.danger') }}
      </div>

      <div v-if="$te('request.alert.src.' + request.srcPaySystem.alias +  '.warning')" class="alert alert-warning"
           role="alert">
        {{ $t('request.alert.src.' + request.srcPaySystem.alias + '.warning') }}
      </div>

      <div v-if="request.srcPaySystem.currency.isoCode == 'BTC'
                && request.srcPaySystem.currency.isoCode == 'BTC'">
        <div class="notification_box mt-3 mb-3">

          <div class="notification_box_header">
            <div class="icon"><img src="@/img/information.svg" alt="information"></div>
            <div class="title">{{ $t('payment.status.notification') }}</div>
          </div>

          <div class="notification_box_content mt-3">
            {{ $t('payment.status.note_btc') }}
            <br/>
            <span v-if="request.dstPaySystem.name === 'QIWI'">{{
                $t('payment.status.note_crypto_qiwi')
              }}</span>
          </div>

        </div>

        <div class="section_text text_style_blue mt-2 mb-2">
          {{ $t('payment.status.make_payment') }}<br>
          {{ $t('payment.status.press_payed') }}
        </div>

        <div class="content_box mt-3 mb-3">

          <div class="title mb-3">{{ $t('payment.status.wallet') }}</div>

          <div class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.address"
                   :placeholder="$t('payment.status.wallet_address')"
                   readonly=""
                   style="width: 400px">

            <div id="copy_wallet_number" class="additional_button"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 data-toggle="popover"
                 title="Popover title"
                 @click="copy(request.invoice.data.address)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div class="title mt-4">{{ $t('payment.status.amount') }}</div>

          <div class="input_wrapper">
            <div class="input-group">
              <input :value="request.invoice.amount" aria-describedby="basic-addon2"
                     aria-label="Recipient's username"
                     class="form-control"
                     readonly=""
                     type="text">
              <div class="input-group-append">
                      <span id="basic-addon2" class="input-group-text">{{
                          request.srcPaySystem.currency.isoCode
                        }}</span>
              </div>
            </div>

            <div class="additional_button" @click="copy(request.invoice.amount)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="request.invoice.type == 'CRYPTO' && request.srcPaySystem.currency.isoCode == 'XMR'">
        <div class="notification_box mt-3 mb-3">

          <div class="notification_box_header">
            <div class="icon"><img src="@/img/information.svg"></div>
            <div class="title">{{ $t('payment.status.notification') }}</div>
          </div>

          <div class="notification_box_content mt-3">
            {{ $t('payment.status.note_xmr') }}
            <br/>
            <span v-if="request.dstPaySystem.name === 'QIWI'">{{
                $t('payment.status.note_crypto_qiwi')
              }}</span>
          </div>

        </div>

        <div class="section_text text_style_blue mt-2 mb-2">
          {{ $t('payment.status.make_payment') }}<br>
          {{ $t('payment.status.press_payed') }}
        </div>

        <div class="content_box mt-3 mb-3">

          <div class="title mb-3">{{ $t('payment.status.wallet') }}</div>

          <div class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.address"
                   :placeholder="$t('payment.status.wallet_address')"
                   readonly=""
                   style="width: 400px">

            <div id="copy_wallet_number" class="additional_button"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 data-toggle="popover"
                 title="Popover title"
                 @click="copy(request.invoice.data.address)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div class="title mt-4">{{ $t('payment.status.amount') }}</div>

          <div class="input_wrapper">
            <div class="input-group">
              <input :value="request.invoice.amount" aria-describedby="basic-addon2"
                     aria-label="Recipient's username"
                     class="form-control"
                     readonly=""
                     type="text">
              <div class="input-group-append">
                      <span id="basic-addon2" class="input-group-text">{{
                          request.srcPaySystem.currency.isoCode
                        }}</span>
              </div>
            </div>

            <div class="additional_button" @click="copy(request.invoice.amount)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>
        </div>
      </div>

      <div
          v-if="request.invoice.type == 'CRYPTO' && !['BTC', 'LTC', 'ETH', 'XMR', 'TRX', 'USDTTRC20'].includes(request.srcPaySystem.currency.isoCode)">
        <div class="notification_box mt-3 mb-3">

          <div class="notification_box_header">
            <div class="icon"><img src="@/img/information.svg"></div>
            <div class="title">{{ $t('payment.status.notification') }}</div>
          </div>

          <div class="notification_box_content mt-3">
            {{ $t('payment.status.note_' + request.srcPaySystem.currency.isoCode.toLowerCase()) }}
            <br/>
            <span v-if="request.dstPaySystem.name === 'QIWI'">{{
                $t('payment.status.note_crypto_qiwi')
              }}</span>
          </div>

        </div>

        <div class="section_text text_style_blue mt-2 mb-2">
          {{ $t('payment.status.make_payment') }}<br>
          {{ $t('payment.status.press_payed') }}
        </div>

        <div class="content_box mt-3 mb-3">

          <div class="title mb-3">{{ $t('payment.status.wallet') }}</div>

          <div class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.address"
                   :placeholder="$t('payment.status.wallet_address')"
                   readonly=""
                   style="width: 400px">

            <div id="copy_wallet_number" class="additional_button"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 data-toggle="popover"
                 title="Popover title"
                 @click="copy(request.invoice.data.address)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div v-if="request.invoice.data.memo != null">
            <div class="title mt-4">Memo</div>

            <div class="input_wrapper">
              <input id="input_wallet_number" :value="request.invoice.data.memo"
                     readonly=""
                     style="width: 400px">

              <div class="additional_button" @click="copy(request.invoice.data.memo)">
                <div><img src="@/img/copy.svg"></div>
              </div>
            </div>
          </div>

          <div class="title mt-4">{{ $t('payment.status.amount') }}</div>

          <div class="input_wrapper">
            <div class="input-group">
              <input :value="request.invoice.amount" aria-describedby="basic-addon2"
                     aria-label="Recipient's username"
                     class="form-control"
                     readonly=""
                     type="text">
              <div class="input-group-append">
                      <span id="basic-addon2" class="input-group-text">{{
                          request.srcPaySystem.currency.isoCode
                        }}</span>
              </div>
            </div>

            <div class="additional_button" @click="copy(request.invoice.amount)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="request.invoice.type == 'CRYPTO' && request.srcPaySystem.currency.isoCode == 'ETH'">
        <div class="notification_box mt-3 mb-3">

          <div class="notification_box_header">
            <div class="icon"><img src="@/img/information.svg"></div>
            <div class="title">{{ $t('payment.status.notification') }}</div>
          </div>

          <div class="notification_box_content mt-3">
            {{ $t('payment.status.note_eth') }}
            <br/>
            <span v-if="request.dstPaySystem.name === 'QIWI'">{{
                $t('payment.status.note_crypto_qiwi')
              }}</span>
          </div>

        </div>

        <div class="section_text text_style_blue mt-2 mb-2">
          {{ $t('payment.status.make_payment') }}<br>
          {{ $t('payment.status.press_payed') }}
        </div>

        <div class="content_box mt-3 mb-3">

          <div class="title mb-3">{{ $t('payment.status.wallet') }}</div>

          <div class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.address"
                   :placeholder="$t('payment.status.wallet_address')"
                   readonly=""
                   style="width: 400px">

            <div id="copy_wallet_number" class="additional_button"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 data-toggle="popover"
                 title="Popover title"
                 @click="copy(request.invoice.data.address)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div class="title mt-4">{{ $t('payment.status.amount') }}</div>

          <div class="input_wrapper">
            <div class="input-group">
              <input :value="request.invoice.amount" aria-describedby="basic-addon2"
                     aria-label="Recipient's username"
                     class="form-control"
                     readonly=""
                     type="text">
              <div class="input-group-append">
                      <span id="basic-addon2" class="input-group-text">{{
                          request.srcPaySystem.currency.isoCode
                        }}</span>
              </div>
            </div>

            <div class="additional_button" @click="copy(request.invoice.amount)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>
        </div>
      </div>


      <div v-if="request.invoice.type == 'CRYPTO' && (request.srcPaySystem.currency.isoCode == 'TRX'
  ||  request.srcPaySystem.currency.isoCode == 'USDTTRC20')">
        <div class="notification_box mt-3 mb-3">

          <div class="notification_box_header">
            <div class="icon"><img src="@/img/information.svg"></div>
            <div class="title">{{ $t('payment.status.notification') }}</div>
          </div>

          <div class="notification_box_content mt-3">
            {{ $t('payment.status.note_tron') }}
            <br/>
            <span v-if="request.dstPaySystem.name === 'QIWI'">{{
                $t('payment.status.note_crypto_qiwi')
              }}</span>
          </div>

        </div>

        <div class="section_text text_style_blue mt-2 mb-2">
          {{ $t('payment.status.make_payment') }}<br>
          {{ $t('payment.status.press_payed') }}
        </div>

        <div class="content_box mt-3 mb-3">

          <div class="title mb-3">{{ $t('payment.status.wallet') }}</div>

          <div class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.address"
                   :placeholder="$t('payment.status.wallet_address')"
                   readonly=""
                   style="width: 400px">

            <div id="copy_wallet_number" class="additional_button"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 data-toggle="popover"
                 title="Popover title"
                 @click="copy(request.invoice.data.address)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div class="title mt-4">{{ $t('payment.status.amount') }}</div>

          <div class="input_wrapper">
            <div class="input-group">
              <input :value="request.invoice.amount" aria-describedby="basic-addon2"
                     aria-label="Recipient's username"
                     class="form-control"
                     readonly=""
                     type="text">
              <div class="input-group-append">
                      <span id="basic-addon2" class="input-group-text">{{
                          request.srcPaySystem.currency.isoCode
                        }}</span>
              </div>
            </div>

            <div class="additional_button" @click="copy(request.invoice.amount)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="request.invoice.type == 'CRYPTO' && request.srcPaySystem.currency.isoCode == 'LTC'">
        <div class="notification_box mt-3 mb-3">

          <div class="notification_box_header">
            <div class="icon"><img src="@/img/information.svg"></div>
            <div class="title">{{ $t('payment.status.notification') }}</div>
          </div>

          <div class="notification_box_content mt-3">
            {{ $t('payment.status.note_ltc') }}
            <br/>
            <span v-if="request.dstPaySystem.name === 'QIWI'">{{
                $t('payment.status.note_crypto_qiwi')
              }}</span>
          </div>

        </div>

        <div class="section_text text_style_blue mt-2 mb-2">
          {{ $t('payment.status.make_payment') }}<br>
          {{ $t('payment.status.press_payed') }}
        </div>

        <div class="content_box mt-3 mb-3">

          <div class="title mb-3">{{ $t('payment.status.wallet') }}</div>

          <div class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.address"
                   :placeholder="$t('payment.status.wallet_address')"
                   readonly=""
                   style="width: 400px">

            <div id="copy_wallet_number" class="additional_button"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 data-toggle="popover"
                 title="Popover title"
                 @click="copy(request.invoice.data.address)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div class="title mt-4">{{ $t('payment.status.amount') }}</div>

          <div class="input_wrapper">
            <div class="input-group">
              <input :value="request.invoice.amount" aria-describedby="basic-addon2"
                     aria-label="Recipient's username"
                     class="form-control"
                     readonly=""
                     type="text">
              <div class="input-group-append">
                <span id="basic-addon2" class="input-group-text">LTC</span>
              </div>
            </div>

            <div class="additional_button" @click="copy(request.invoice.amount)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="request.invoice.type == 'CARD'">
        <div class="section_text text_style_blue mt-2 mb-2">
          {{ $t('payment.status.make_payment') }}<br>
          {{ $t('payment.status.press_payed') }}
        </div>

        <div class="content_box mt-3 mb-3">

          <div class="title mb-3">Номер карты</div>

          <div class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.number" readonly=""
                   :placeholder="$t('payment.status.wallet_address')">

            <div class="additional_button" id="copy_wallet_number" data-toggle="popover"
                 title="Popover title"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 @click="copy(request.invoice.data.number)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div v-if="request.invoice.data.owner" class="title mb-3">Владелец</div>
          <div v-if="request.invoice.data.owner" class="input_wrapper">
            <input id="input_wallet_number" :value="request.invoice.data.owner" readonly=""
                   :placeholder="Владелец">

            <div class="additional_button" id="copy_wallet_number" data-toggle="popover"
                 title="Popover title"
                 data-content="And here's some amazing content. It's very engaging. Right?"
                 @click="copy(request.invoice.data.owner)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>

          <div class="title mt-4">{{ $t('payment.status.amount') }}</div>

          <div class="input_wrapper">
            <div class="input-group">
              <input type="text" class="form-control" readonly="" :value="request.invoice.amount"
                     aria-label="Recipient's username"
                     aria-describedby="basic-addon2">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">RUB</span>
              </div>
            </div>

            <div class="additional_button" @click="copy(request.invoice.amount)">
              <div><img src="@/img/copy.svg"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="request.invoice.type == 'WEB'">
        <div class="section_text text_style_blue mt-4 mb-">{{ $t('payment.status.note_web') }}</div>

        <button class="btn btn-success mt-2" type="button" v-on:click="openPayForm">
          {{ $t('payment.status.open_payment_page') }}
        </button>

        <div class="section_text text_style_blue mt-3">
          {{ $t('payment.status.press_payed') }}
        </div>

      </div>


      <div v-else-if="request.invoice.type == 'WEB'">
        <div class="section_text text_style_blue mt-4 mb-">
          {{ $t('payment.status.note_web') }}
        </div>

        <button class="btn btn-success mt-2" type="button" v-on:click="openPayForm">
          {{ $t('payment.status.open_payment_page') }}
        </button>

        <div class="section_text text_style_blue mt-3">
          {{ $t('payment.status.press_payed') }}
        </div>

      </div>

      <div v-else-if="request.invoice.type == 'WEB_FORM'">
        <div class="section_text text_style_blue mt-4 mb-">
          {{ $t('payment.status.note_web') }}
        </div>

        <div v-html="payForm"/>

        <div class="section_text text_style_blue mt-3">
          {{ $t('payment.status.press_payed') }}
        </div>
      </div>

      <div v-else-if="request.invoice.type == 'WEB_WIDGET'">
        <div id="widget-container"></div>
      </div>

      <button class="btn btn-primary mt-3" type="button" v-on:click="payed">{{
          $t('payment.status.payed')
        }}
      </button>
    </div>
    <div v-if="request.status == 'DONE'">
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <section class="status">
        <div class="container status_style">
          <div class="status_box_wrapper">
            <div class="icon">
              <img src="@/img/success.svg">
            </div>
            <div class="title mt-3">
              {{ $t('payment.status.success') }}
            </div>
            <div v-if="request.data.txHash" class="status_success mt-3">
              <span>{{ $t('payment.status.tx_in_net') }} {{ request.dstPaySystem.name }}: </span>
              <span><a :href="hashLink" target="_blank">{{ request.data.txHash }}</a></span>
            </div>
            <div class="status_buttons mt-3">
              <router-link to="/profile">{{ $t('payment.status.to_profile') }}</router-link>
              <router-link class="btn" tag="button" to="/">{{
                  $t('payment.status.one_more_exchange')
                }}
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="request.status == 'ERROR'">
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <section class="status">
        <div class="container status_style">
          <div class="status_box_wrapper">
            <div class="icon">
              <img src="@/img/error.svg">
            </div>
            <div class="title mt-3">
              {{ $t('payment.status.error') }}
            </div>
            <div class="mt-3">
              <span>{{ $t('payment.status.contact_support') }}</span>
            </div>
            <div class="status_buttons mt-3">
              <router-link to="/profile">{{ $t('payment.status.to_profile') }}</router-link>
              <router-link class="btn" tag="button" to="/">{{
                  $t('payment.status.one_more_exchange')
                }}
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="request.status == 'EXPIRED'">
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <section class="status">
        <div class="container status_style">
          <div class="status_box_wrapper">
            <div class="icon">
              <img src="@/img/expired.svg">
            </div>
            <div class="title mt-3">
              {{ $t('payment.status.expired') }}
            </div>
            <div class="status_buttons mt-3">
              <router-link to="/profile">{{ $t('payment.status.to_profile') }}</router-link>
              <router-link class="btn" tag="button" to="/">{{
                  $t('payment.status.one_more_exchange')
                }}
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="request.status == 'AML_RISK'">
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <section class="status">
        <div class="container status_style">
          <div class="status_box_wrapper">
            <div class="icon">
              <img src="@/img/aml.svg">
            </div>
            <div class="title mt-3">
              {{ $t('payment.status.aml') }}
            </div>
            <div class="mt-3">
              <span>{{ $t('payment.status.contact_support') }}</span>
            </div>
            <div class="status_buttons mt-3">
              <router-link to="/profile">{{ $t('payment.status.to_profile') }}</router-link>
              <router-link class="btn" tag="button" to="/">{{
                  $t('payment.status.one_more_exchange')
                }}
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="status">
      <div class="container" style="width: 100%;">
        <br>
        <br>
        <br>
        <br>
        <br>
        <ProgressBar v-if="request.status == 'PAYED'" type="incoming"/>
        <ProgressBar v-if="request.status == 'FOR_TRANSFER'" type="outgoing"/>
      </div>
    </section>
  </div>
</template>

<script>
import Field from "@/components/payment/Field";
import Verification from "@/components/payment/Verification";
import ProgressBar from "@/components/payment/ProgressBar";
import {required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import moment from "moment/moment";

export default {
  name: 'Status',
  props: ['request'],
  components: {
    Field,
    Verification,
    ProgressBar
  },

  data: function () {
    return {
      email: "",
      emailValid: true,
      showRequisites: true,
      showPayAction: false,
      showSuccess: false,
      showError: false,
      nextButtonDisabled: false,
      progressState: 1,
      srcRequisites: {},
      dstRequisites: {},
      agree: false,
      result: {
        txn: ''
      },
      progressBarType: "incoming",
      progressBarShow: false,

      checkPayedPolling: null,
      checkTransferredPolling: null,
      updateRequestPolling: null

    }
  },

  validations() {
    let that = this;

    let validation = {
      srcRequisites: {},
      dstRequisites: {},
    };

    if (this.user === null) {
      validation.email = {
        required,
        regexp(v) {
          return (
              /^.+@.+\..+$/.test(v)
          );
        },
        valid() {
          return that.emailValid;
        }
      }
    }

    validation.agree = {
      checked: value => value === true
    }

    this.request.srcPaySystem.srcFields.forEach(function (item) {
      validation['srcRequisites'][item.name] = {
        required,
        regexp(v) {
          return (
              new RegExp(item.validator).test(v)
          );
        }
      }
    })

    this.request.dstPaySystem.dstFields.forEach(function (item) {
      validation['dstRequisites'][item.name] = {
        required,
        regexp(v) {
          return (
              new RegExp(item.validator).test(v)
          );
        }
      }
    })

    return validation
  },

  methods: {
    ...mapActions(['setRequest', 'saveRequisites',
      'setInvoice', 'fetchRequest'
    ]),
    next: function () {

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.nextButtonDisabled = true
      let req = this.request;

      req.srcRequisites = this.srcRequisites
      req.dstRequisites = this.dstRequisites

      if (this.user === null) {
        req.email = this.email
      } else {
        req.email = this.user.username
      }

      this.setRequest(req)
      this.saveRequisites((data) => {

        this.setRequest(data)

        this.progressState = 2;
      })
    },
    openPayForm: function () {
      window.open(this.request.invoice.data.paymentLink, '_blank')
    },
    payed: function () {

      this.showRequisites = false;
      this.showPayAction = false;

      this.progressBarType = "incoming";
      this.progressBarShow = true;

      axios.post('/api/exchange/' + this.request.uuid + '/payed').then(
          response => {
            this.setRequest(response.data)
            this.progressState = 3;
          }
      );
    },
    copy: async function (value) {
      await navigator.clipboard.writeText(value);
    },
    getImg(alias) {
      return require('@/img/ps/' + alias + '.png')
    },
    moment: function (arg) {
      return moment(arg);
    }
  },

  computed: {
    ...mapGetters(['currentPair',
      'getInvoice',
      'user'
    ]),
    hashLink() {
      switch (this.request.dstPaySystem.alias) {
        case 'BTC':
          return 'https://mempool.space/tx/' + this.request.data.txHash;
        case 'ETH':
          return 'https://etherscan.io/tx/' + this.request.data.txHash;
        case 'LTC':
          return 'https://live.blockcypher.com/ltc/tx/' + this.request.data.txHash;
        case 'XMR':
          return 'https://blockchair.com/monero/transaction/' + this.request.data.txHash;
        case 'TRX':
          return 'https://tronscan.org/#/transaction/' + this.request.data.txHash;
        default:
          break;
      }
      return this.$store.state.result;
    },
    payForm() {
      let form = "<form target=\"_blank\" action=\"" + this.request.invoice.data.paymentLink + "\" method=\"POST\">";

      for (let key in this.request.invoice.data.formData) {
        form += "<input type=\"hidden\"  name=\"" + key + "\" value=\"" + this.request.invoice.data.formData[key] + "\">"
      }

      form += "<button type=\"submit\" class=\"btn btn-success mt-2\">" + this.$t('payment.status.open_payment_page') + "</button>\n" +
          "</form>";

      return form;
    }
  },

  watch: {
    email: async function (value) {

      if (this.request.srcPaySystem.needDocsVerification === false) return;

      const res = await axios.get('/api/auth/checkEmail?email=' + value);

      const data = await res.data
      this.emailValid = data.valid
    }
  },

  mounted() {
    const timestamp = Date.now();
    const set = {
      partner_token: 'c7e5c5a7-5469-4d20-9777-f67ae512ae2d',
      target_element: 'widget-container',
      timestamp: timestamp,
      lang: 'ru',
      from_currency: this.request.srcPaySystem.currency.isoCode,
      from_amount: this.request.srcAmount * 100,
      to_currency: 'TRC20USDT',
      partner_operation_id: this.request.uuid,
      to_account: 'TDSdziN7NQ7Mf1ztJfJJNWVg88RMrLvK4v'
    }

    let that = this;

    set.onOperationSuccess = () => that.payed()

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(set)
    };

    if (this.request.dstPaySystem.properties.withdrawal_provider === 'payassist-sbp') {
      this.dstRequisites.bank_id = 100000000111
    }
    
    fetch('/api/payment/itez/sign', options)
        .then(response => {
          response.text().then(text => {
            set.signature = text
            window.ItezWidget.run(set, 'POST');
          })

        })
        .catch(err => console.error(err));
  }
}
</script>

