<template>

  <section class="mt-4 pb-5">

    <div v-if="request.id != null" class="container">
      <div class="inner_page_two_sides">
        <div class="left_side max-width-30">

          <div :class="['active_' + progressState]" class="progress_bar active_1 mt-5">
            <div class="progress_bar_circle">1</div>
            <div class="progress_bar_line"></div>
            <div class="progress_bar_circle">2</div>
            <div class="progress_bar_line"></div>
            <div class="progress_bar_circle">3</div>
          </div>
        </div>

        <div class="right_side">
          <div class="section_payment_breadcrumbs breadcrumbs_border">
            <div>№ {{ request.id }}</div>
            <div>
              <div class="payment_logo"><img :src="getImg(request.srcPaySystem.alias)"></div>
              <div class="payment_text">{{ request.srcAmount }} {{ request.srcPaySystem.currency.isoCode }}</div>
              <div class="payment_arrow"><img src="@/img/arrow_left.svg"></div>
              <div class="payment_logo"><img :src="getImg(request.dstPaySystem.alias)" width="10px"></div>
              <div class="payment_text">{{ request.dstAmount }} {{ request.dstPaySystem.currency.isoCode }}</div>
            </div>
          </div>

          <Status :request="request"/>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import moment from 'moment';
import Status from "@/views/payment/Status";

export default {
  name: 'Payment',

  components: {
    Status
  },

  props: {
    uuid: String
  },

  data: function () {
    return {
      showRequisites: true,
      showPayAction: false,
      showSuccess: false,
      showError: false,

      nextButtonDisabled: false,

      progressState: 1,

      srcRequisites: {
      },

      dstRequisites: {
      },
      agree: true,

      result: {
        txn: ''
      },

      progressBarType: "incoming",
      progressBarShow: false,

      checkPayedPolling: null,
      checkTransferredPolling: null,

      updateRequestPolling: null

    }
  },

  validations() {
    var validation = {
      srcRequisites: {},
      dstRequisites: {},
    }

    if (this.user === null) {
      validation.email = {
        required,
        regexp(v) {
          return (
              /^.+@.+\..+$/.test(v)
          );
        }
      }
    }

    validation.agree = {
      checked: value => value === true
    }

    this.request.srcPaySystem.srcFields.forEach(function (item) {
      validation['srcRequisites'][item.name] = {
        required,
        regexp(v) {
          return (
              new RegExp(item.validator).test(v)
          );
        }
      }
    })

    this.request.dstPaySystem.dstFields.forEach(function (item) {
      validation['dstRequisites'][item.name] = {
        required,
        regexp(v) {
          return (
              new RegExp(item.validator).test(v)
          );
        }
      }
    })

    return validation
  },

  methods: {
    ...mapActions(['setRequest', 'saveRequisites',
      'setInvoice', 'fetchRequest'
    ]),
    next: function () {

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.nextButtonDisabled = true
      let req = this.request;

      req.srcRequisites = this.srcRequisites
      req.dstRequisites = this.dstRequisites

      if (this.user === null) {
        req.email = this.email
      } else {
        req.email = this.user.username
      }

      this.setRequest(req)
      this.saveRequisites((data) => {

        this.setRequest(data)

        this.progressState = 2;
      })
    },
    openPayForm: function () {
      window.open(this.request.invoice.data.paymentLink, '_blank')
    },
    payed: function () {

      this.showRequisites = false;
      this.showPayAction = false;

      this.progressBarType = "incoming";
      this.progressBarShow = true;

      axios.post('/api/exchange/' + this.request.uuid + '/payed').then(
          response => {

            this.setRequest(response.data)
            this.progressState = 3;
            this.checkPayed()
          }
      );
    },
    copy: async function (value) {
      await navigator.clipboard.writeText(value);
    },
    getImg(alias) {
      return require('@/img/ps/' + alias + '.png')
    },
    moment: function (arg) {
      return moment(arg);
    }
  },

  watch: {
    request: function () {
      if (this.request.status === 'DONE') {
        this.result.txn = this.request.data.txHash;
      }
    }
  },

  computed: {
    ...mapGetters(['request', 'currentPair',
      'getInvoice',
      'user'
    ])
  },

  async created() {
    await this.fetchRequest(this.uuid)

    this.updateRequestPolling = setInterval(() => {
      this.fetchRequest(this.uuid)
    }, 10000)
  },

  destroyed() {
    clearInterval(this.updateRequestPolling)
  }
}
</script>
