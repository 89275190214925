<template>
  <div>
    <input class="mt-3" :placeholder="$t('field.' + field.name + '.ph')" v-model.trim="value[field.name]" :autocomplete="field.autocomplete"/>
    <br/>
    <span>{{ $t('field.' + field.name + '.help') }}</span>
    <div style="font-size: 12px; color: #ff1313" v-if="validation[field.name].$dirty && !validation[field.name].required">{{ $t('payment.field.required') }}</div>
    <div style="font-size: 12px; color: #ff1313" v-else-if="validation[field.name].$dirty && !validation[field.name].regexp">{{ $t('payment.field.invalid_format') }}</div>
  </div>

</template>

<script>
export default {
  name: 'Field',
  props: ['field', 'value', 'validation']

}
</script>

