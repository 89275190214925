<template>
  <div class="status_box_wrapper">
    <div class="section_progress mt-4">
      <div class="progress_box">
        <div>
          <div>
            <div>
              <img :src="icon1" alt="Bob">
            </div>
          </div>
        </div>

        <div>
          <div>
            <svg>
              <path d="m 12.5,20 15,0 0,0 -15,0 z" class="led one"></path>
              <path d="m 32.5,20 15,0 0,0 -15,0 z" class="led two"></path>
              <path d="m 52.5,20 15,0 0,0 -15,0 z" class="led three"></path>
              <path d="m 72.5,20 15,0 0,0 -15,0 z" class="led four"></path>
            </svg>


          </div>
        </div>

        <div>
          <div>
            <div>
              <img :src="icon2" alt="Alice">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="progress_box_title mt-3">
      <span>{{ text }}</span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: ['type'],

  data: function () {
    return {}
  },

  computed: {
    text: function () {
      return this.type === 'incoming' ? this.$t('payment.progress_bar.confirmation') : this.$t('payment.progress_bar.sending_payment');
    },

    icon1: function () {
      return this.type === 'incoming' ? require('@/img/progress_icon_1.svg') : require('@/img/progress_icon_2.svg');
    },

    icon2: function () {
      return this.type === 'incoming' ? require('@/img/progress_icon_2.svg') : require('@/img/progress_icon_1.svg');
    }
  }

}
</script>

