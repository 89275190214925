<template>
  <div>
    <div class="section_header mt-3">
      <span>{{ $t('payment.verification.verification') }}</span>
    </div>

    <div class="section_text mt-2 mb-4">
      {{ $t('payment.verification.notice') }}
    </div>

    <div v-if="request.srcPaySystem.needVerification && request.verification.bankCard != null"
         :set="bankCard = request.verification.bankCard"
         class="verification_block_with_upload">
      <div class="verification_block_with_upload_header">
        <div class="header_image">
          <img src="@/img/verification_4.svg">
        </div>
        <div class="header_title">
          <div class="title">
            {{ $t('payment.verification.card_photo') }}
            <span v-if="bankCard.status === 'VERIFIED' "
                  class="ml-2 badge badge-success">{{ $t('payment.verification.approved') }}</span>
            <span v-else-if="bankCard.status === 'ERROR'"
                  class="ml-2 badge badge-danger">{{ $t('payment.verification.error') }}</span>
            <span v-else-if="bankCard.status === 'UPLOADED'"
                  class="ml-2 badge badge-warning">{{ $t('payment.verification.wait') }} <b-spinner
                variant="light" type="grow" small></b-spinner></span>
          </div>
          <div v-if="bankCard.status == 'ERROR' && bankCard.comment != null" class="description error">
            {{ bankCard.comment }}
          </div>

          <div v-if="request.srcPaySystem.properties.deposit_provider == 'firekassa_v2'" class="description alert-warning" v-html="$t('payment.verification.firekassa_card_help')"></div>
          <div v-else class="description alert-warning" v-html="$t('payment.verification.card_help')"></div>
        </div>
      </div>

      <div class="verification_block_with_upload_content">
        <label class="upload_content_add" :class="{ disabled:cardImageData !== null }">
          <input type="file" ref="cardPhoto" v-on:change="uploadCardPhoto()" accept="image/*"/>
          <div class="upload_content_add_wrapper">
            <div class="icon">
              <img src="@/img/add.svg">
            </div>
            <div class="title">
              {{ $t('payment.verification.upload') }}
            </div>
          </div>
        </label>

        <div class="upload_content_images" v-if="cardImageData !== null">
          <div>
            <div class="delete" v-if="bankCard.status != 'VERIFIED'"
                 v-on:click="cardPhoto = null; cardImageData = null; this.$refs.cardPhoto = null; this.request.verification.status == 'NEW'">
              <img src="@/img/delete.svg">
            </div>
            <img v-if="cardImageData !== null" :src="cardImageData">
          </div>
        </div>
      </div>
    </div>

    <div v-if="request.srcPaySystem.needDocsVerification && request.verification.passport != null"
         :set="passport = request.verification.passport"
         class="verification_block_with_upload mt-5">
      <div class="verification_block_with_upload_header">
        <div class="header_image">
          <img src="@/img/verification_3.svg">
        </div>
        <div class="header_title">
          <div class="title">
            {{ $t('payment.verification.document_photo') }}
            <span v-if="passport.status === 'VERIFIED' "
                  class="ml-2 badge badge-success">{{ $t('payment.verification.approved') }}</span>
            <span v-else-if="passport.status === 'VERIFICATION'"
                  class="ml-2 badge badge-warning">{{ $t('payment.verification.wait') }} <b-spinner
                variant="light" type="grow" small></b-spinner></span>
            <span v-else-if="passport.status === 'ERROR'"
                  class="ml-2 badge badge-danger">{{ $t('payment.verification.error') }}</span>
          </div>
          <div v-if="passport.status == 'ERROR' && passport.comment != null" class="description error">
            {{ passport.comment }}
          </div>
          <div class="description">
            {{ $t('payment.verification.document_help') }}
          </div>
        </div>
      </div>

      <div class="verification_block_with_upload_content">
        <label class="upload_content_add" :class="{ disabled:passportImageData !== null }">
          <input type="file" ref="passportPhoto" v-on:change="uploadPassportPhoto()" accept="image/*"/>
          <div class="upload_content_add_wrapper">
            <div class="icon">
              <img src="@/img/add.svg">
            </div>
            <div class="title">
              Загрузить
            </div>
          </div>
        </label>

        <div class="upload_content_images" v-if="passportImageData !== null">
          <div>
            <div class="delete" v-if="passport.status != 'VERIFIED'"
                 v-on:click="passportPhoto = null; passportImageData = null; this.$refs.passportPhoto = null; this.request.verification.status == 'NEW'">
              <img src="@/img/delete.svg">
            </div>
            <img v-if="passportImageData !== null" :src="passportImageData">
          </div>
        </div>
      </div>

    </div>

    <div v-if="request.srcPaySystem.needDocsVerification && request.verification.selfie != null"
         :set="selfie = request.verification.selfie"
         class="verification_block_with_upload mt-5">
      <div class="verification_block_with_upload_header">
        <div class="header_image">
          <img src="@/img/verification_2.svg">
        </div>
        <div class="header_title">
          <div class="title">
            {{ $t('payment.verification.selfie_photo') }}
            <span v-if="selfie.status === 'VERIFIED' "
                  class="ml-2 badge badge-success">{{ $t('payment.verification.approved') }}</span>
            <span v-else-if="selfie.status === 'VERIFICATION'"
                  class="ml-2 badge badge-warning">{{ $t('payment.verification.wait') }} <b-spinner
                variant="light" type="grow" small></b-spinner></span>
            <span v-else-if="selfie.status === 'ERROR'"
                  class="ml-2 badge badge-danger">{{ $t('payment.verification.error') }}</span>
          </div>
          <div v-if="selfie.status == 'ERROR' && selfie.comment != null" class="description error">
            {{ selfie.comment }}
          </div>
          <div class="description">
            {{ $t('payment.verification.selfie_help') }}
          </div>
        </div>
      </div>

      <div class="verification_block_with_upload_content">
        <label class="upload_content_add" :class="{ disabled:selfieImageData !== null }">
          <input type="file" ref="selfiePhoto" v-on:change="uploadSelfiePhoto()" accept="image/*"/>
          <div class="upload_content_add_wrapper">
            <div class="icon">
              <img src="@/img/add.svg">
            </div>
            <div class="title">
              {{ $t('payment.verification.upload') }}
            </div>
          </div>
        </label>

        <div class="upload_content_images" v-if="selfieImageData !== null">
          <div>
            <div class="delete" v-if="selfie.status != 'VERIFIED'"
                 v-on:click="selfiePhoto = null; selfieImageData = null; this.$refs.selfiePhoto = null; this.request.verification.status == 'NEW'">
              <img src="@/img/delete.svg">
            </div>
            <img v-if="selfieImageData !== null" :src="selfieImageData">
          </div>
        </div>
      </div>
    </div>

    <div v-if="error" class="alert alert-danger mt-4" role="alert">
      Ошибка отправки формы. Максимальный размер изображение - 10 Мб.
    </div>

    <hr/>
    <button type="button" class="btn btn-primary mt-2" v-on:click="submit" :disabled="
            this.request.verification.status == 'VERIFICATION' && !this.error
        ">{{ $t('common.next') }}
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Verification',
  props: ['request'],

  data() {
    return {
      file: '',

      uploaded: false,
      verified: false,
      bankCard: {},
      updateRequestPolling: null,


      cardPhoto: null,
      cardImageData: null,
      passportPhoto: null,
      passportImageData: null,
      selfiePhoto: null,
      selfieImageData: null,

      error: false
    }
  },

  methods: {

    uploadCardPhoto() {
      this.cardPhoto = this.$refs.cardPhoto.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.cardImageData = e.target.result;
      }
      reader.readAsDataURL(this.$refs.cardPhoto.files[0]);
    },

    uploadPassportPhoto() {
      this.passportPhoto = this.$refs.passportPhoto.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.passportImageData = e.target.result;
      }
      reader.readAsDataURL(this.$refs.passportPhoto.files[0]);
    },

    uploadSelfiePhoto() {
      this.selfiePhoto = this.$refs.selfiePhoto.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.selfieImageData = e.target.result;
      }
      reader.readAsDataURL(this.$refs.selfiePhoto.files[0]);
    },

    uploadPhoto() {
      this.file = this.$refs.photo.files[0];
    },

    uploadNewPhoto() {
      this.file = this.$refs.newPhoto.files[0];
      this.submitFile();
    },

    submit() {
      let formData = new FormData();
      let that = this;

      let verification = {
        "bankCard": {
          "number": this.request.srcRequisites.cardNumber
        }
      };
      formData.append('cardPhoto', this.cardPhoto);
      formData.append('passportPhoto', this.passportPhoto);
      formData.append('selfiePhoto', this.selfiePhoto);
      formData.append('verification', JSON.stringify(verification));
      axios.post('/api/exchange/request/' + this.request.uuid + '/verification',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      )
          .then(() => {
            this.request.verification.status = 'VERIFICATION'
            this.error = false
          })
          .catch((error) => {
            that.error = true
            console.log(error.toJSON())
          });
    },
  },
  computed: {
    active() {
      switch (this.bankCard.status) {
        case 'UPLOADED':
          return 2
        case 'VERIFIED':
          return 3
        case 'ERROR':
          return 4
        default:
          return 1
      }
    }
  },
  mounted() {
    let verification = this.request.verification;

    if (verification.bankCard.photoUrl != null) {
      this.cardImageData = 'https://storage.yandexcloud.net/mmnt-os-1/' + verification.bankCard.photoUrl
    }

    if (verification.passport.photoUrl != null) {
      this.passportImageData = 'https://storage.yandexcloud.net/mmnt-os-1/' + verification.passport.photoUrl
    }

    if (verification.selfie.photoUrl != null) {
      this.selfieImageData = 'https://storage.yandexcloud.net/mmnt-os-1/' + verification.selfie.photoUrl
    }
  },
  destroyed() {
    clearInterval(this.updateRequestPolling)
  }
}
</script>

